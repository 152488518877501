.welcomeLabel {
  margin: 10px;
  align-self: center;
}

.space1 {
  width: 20%;
}

.space2 {
  width: 40%;
}
